#cookie-bar {
  background: #444;
  height: auto;
  line-height: 24px;
  color: #eee;
  text-align: center;
  padding: 10px 0;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1200; }

  &.fixed.bottom {
    bottom: 0;
    top: auto; }

  p {
    margin: 0;
    padding: 0; }

  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      text-decoration: none; } }

  .cb-enable {
    display: inline-block;
    position: absolute;
    right: 10px;
    padding: 2px 5px;
    border-radius: 16px;
    background: #888;
    text-decoration: none;
    &:hover {
      background: #ccc;
      color: black; } }

  .cb-disable {
    background: #990000;
    cursor: pointer; }

  .cb-disable:hover {
    background: #bb0000; }

  .cb-policy {
    display: none; } }
