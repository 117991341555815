@media (min-width: 768px) {
  .tm-posts_supertitle {
    text-align: left; } }

#tm_builder_outer_content .tm_pb_posts.tm_pb_module {
  .tm-posts_title_group > :last-child {
    margin-bottom: 0px !important;
    margin-top: 40px !important; }
  .tm-posts_layout-3 .tm-posts_item {
    border: 5px solid #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25); } }

.tile-crop .tm-posts_item {
  border: 5px solid #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25); }

@media (min-width: 768px) {
  .main-navigation .menu {
    margin-left: -18px;
    margin-right: -18px;
    > li {
      margin-left: 18px;
      margin-right: 18px;
      > a {
        font-size: 12px;
        font-size: 1rem !important;
        padding: 5px 0; } } } }
/*increase from .75rem */

.posts-list .hentry {
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25); }

@media (min-width: 1200px) {
  .tile-crop {
    .tm-posts_item {
      max-height: 295px;
      overflow: hidden;
      -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25); }
    .tm-posts_layout-3 img {
      -webkit-transform: translate(0, -25%);
      -ms-transform: translate(0, -25%);
      transform: translate(0, -25%); } } }

@media (min-width: 991px) {
  .hero-crop {
    .tm-posts_item {
      max-height: 400px;
      overflow: hidden;
      border: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important; }
    .tm-posts_layout-3 img {
      -webkit-transform: translate(0, -25%);
      -ms-transform: translate(0, -25%);
      transform: translate(0, -25%); } } }

.team-listing {
  padding: 0px !important; }

/* padding originally 20px 0 */

.breadcrumbs {
  background-color: #efefef !important; }

.btn {
  &.btn-default {
    background-color: #1695bf !important;
    color: #fff !important; }
  padding: 13px 27.5px;

  .container .team-socials_item.team-socials_item_first > & {
    color: #003472 !important; } }


/* Register Now CTA */

aside {
  border: none !important;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  .widget {
    background-color: #fff;
    border: 20px solid #fff !important;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) !important;
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) !important;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) !important; } }

.mks_author_widget {
  background-color: #fff;
  border: 20px solid #fff !important;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) !important; }

.footer-area {
  padding-bottom: 0px !important; }

.site-footer.style-2 .footer-container {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.footer-area {
  padding-top: 50px !important; }

@media (min-width: 993px) {
  .mobilize-HIDE {
    display: block !important; } }

@media (max-width: 993px) {
  .mobilize-HIDE {
    display: none !important; } }

@media (min-width: 768px) {
  .mobilize-center {
    text-align: left !important; } }

@media (max-width: 768px) {
  .mobilize-center {
    text-align: center !important; } }

.single-modern-header .entry-meta > * {
  padding-left: 5px;
  padding-right: 0px;
  color: #fff !important; }

.entry-meta {
  > * {
    padding-left: 5px;
    padding-right: 0px;
    color: #fff !important; }
  padding-left: 5px;
  padding-right: 0px;
  color: #fff !important; }

/*.main-navigation .menu, .main-navigation .menu > li, .main-navigation .menu > li > a {color: #3b2f87 !important;} */

#tm_builder_outer_content .tm_pb_posts.tm_pb_module .tm-posts_layout-3 .tm-posts_item .tm-posts_item_content {
  padding: 20px 20px !important; }

.team-listing_name {
  font-size: 16px !important; }

/*hardcoded padding fixes for HP */
@media (min-width: 543px) {
  .hard-padding {
    .tm-posts_layout-3 .tm_pb_column {
      &:first-child {
        padding-right: 5px;
        padding-left: 0px; }
      &:nth-of-type(2) {
        padding-right: 5px;
        padding-left: 5px; }
      &:nth-of-type(3) {
        padding-right: 0px;
        padding-left: 5px; } }
    .tm-posts_layout-1 .tm_pb_column {
      &:first-child {
        padding-right: 5px;
        padding-left: 0px; }
      &:nth-of-type(2), &:nth-of-type(3) {
        padding-right: 5px;
        padding-left: 5px; }
      &:nth-of-type(4) {
        padding-right: 0px;
        padding-left: 5px; } } }
  .hard-padding-half .tm-posts_layout-3 .tm_pb_column {
    &:first-child {
      padding-right: 5px;
      padding-left: 0px; }
    &:nth-of-type(2) {
      padding-right: 0px;
      padding-left: 5px; } } }

@media (min-width: 544px) and (max-width: 992px) {
  .hard-padding .tm-posts_layout-3 .tm_pb_column img {
    max-height: 100px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hard-padding {
    .tm-posts_layout-3 .tm_pb_column img {
      max-height: 180px; }
    .tm-posts_layout-1 .tm_pb_column img {
      max-height: 140px; } } }

@media (min-width: 1200px) {
  .hard-padding {
    .tm-posts_layout-3 .tm_pb_column img {
      max-height: 230px; }
    .tm-posts_layout-1 .tm_pb_column img {
      max-height: 177px; } } }

/*Nav fixes */
@media (min-width: 768px) and (max-width: 1199px) {
  .site-header.style-4 {
    .main-navigation {
      order: 3 !important; }
    .header-icons {
      order: 5 !important; }
    .header-btn {
      order: 7 !important; } } }

@media (min-width: 995px) and (max-width: 1199px) {
  .site-header.style-4 .main-navigation {
    width: 60%; }
  .main-navigation .menu > li > a {
    font-size: 12px;
    font-size: .8rem !important;
    padding: 5px 0; } }
/*increase from .75rem */

@media (min-width: 768px) and (max-width: 994px) {
  .site-header.style-4 .main-navigation {
    width: 50%; }
  .main-navigation .menu > li > a {
    font-size: 12px;
    font-size: .8rem !important;
    padding: 5px 0; } }

.widget + .widget {
  padding-top: 0px !important; }

.tm-posts_listing {
  padding: 0 0 0px !important;
  margin-bottom: 10px !important; }

@media (max-width: 768px) {
  .site-header.style-4 .header-btn {
    display: none !important; }
  .main-navigation {
    a {
      padding: 0px 22px !important; }
    min-height: 400px !important; } }

.single {
  .related-posts {
    .post-thumbnail {
      .post-thumbnail__img {
        min-height: 115px;
        object-fit: cover; } } } }

main#main {
  header.page-header {
    width: 100%; } }
