/*
 * include the Affinitas icon font
 */
@import "../node_modules/@affinitas/icon-font/dist/styles/affinitas-icon-font-mixins";

@include affinitas-icon-font-face("/wp-content/themes/monstroid2-child/fonts/");

.icon
{
  display: inline-block;
  position: relative;
  line-height: 1;

  &::before {
    @include affinitas-icon-font-styles;
  }
}

@each $key, $char in $affinitas-icons
{
  .icon-#{$key}
  {
    @include affinitas-icon($key);
  }
}