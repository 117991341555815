$regbox-border-radius: 6px;
$regbox-header: linear-gradient(#ffd37b, #ffad0c);
$regbox-background-color: #f3f5f6;
$regbox-body-padding: 35px 23px;
$input-field-height: 35px;
$input-field-background: #e7e7e7;
$input-field-text-color: #55585b;
$slogan-font-size: 23px;
$slogan-font-weight: normal;
$slogan-text-color: #003472;

$validation-failed-color: #d90000;
$validation-passed-color: #56b06c;
$button-submit-text: #fffefe;
$button-submit: #003472;
$button-submit-hover: lighten($button-submit, 10%);


.regbox-grid-container {
  .grid-x {
    position: absolute;
    z-index: 2;
    @include breakpoint(small down) {
      position: relative; } } }

#regbox_wrapper {
  text-align: left;
  max-width: 350px;
  margin-left: 120px;
  @include breakpoint(small down) {
    max-width: 100vw;
    float: none;
    margin-left: initial;
    margin-bottom: initial; }
  form {
    margin: 0;
    padding: 0; }
  label {
    display: block;
    font-size: 13px;
    color: #4d4d4d;
    cursor: pointer;
    font-weight: normal;
    line-height: 1.5; }
  .screenreader {
    display: block !important;
    height: 0 !important;
    width: 0 !important;
    position: absolute !important;
    overflow: hidden !important; }
  a {
    background-color: transparent;
    &:active, &:hover {
      outline: 0; } }
  svg:not(:root) {
    overflow: hidden; }
  button, input {
    color: inherit;
    font: inherit;
    margin: 0; }
  button {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer; }
  input {
    line-height: normal;
    &[type=radio] {
      box-sizing: border-box;
      padding: 0; } }
  * {
    box-sizing: border-box;
    &:before, &:after {
      box-sizing: border-box; } }
  a:hover {
    cursor: pointer; }
  .row {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 960px;
    &:before {
      content: " ";
      display: table; }
    &:after {
      content: " " !important;
      display: table;
      clear: both; }
    .row {
      width: auto;
      margin: 0 -5px;
      max-width: none;
      &:before {
        content: " ";
        display: table; }
      &:after {
        content: " ";
        display: table;
        clear: both; } } }
  .columns {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    float: left; }
  [class*=column] + [class*=column]:last-child {
    float: right; }
  [class*=block-grid-] {
    display: block;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    &:before {
      content: " ";
      display: table; }
    &:after {
      content: " ";
      display: table;
      clear: both; }
    > li {
      display: block;
      height: auto;
      float: left;
      padding: 0 5px 10px; } }
  button, .button {
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 0;
    position: relative;
    text-decoration: none;
    text-align: center;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    padding: 8px 16px 9px;
    font-size: 13px;
    background-color: #008CBA;
    border: 0 solid #007095;
    color: #fff;
    transition: background-color 300ms ease-out; }
  button {
    &:hover, &:focus {
      background-color: #007095; } }
  .button {
    &:hover, &:focus {
      background-color: #007095; } }
  button {
    &:hover, &:focus {
      color: #fff; } }
  .button {
    &:hover, &:focus {
      color: #fff; } }
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  form {
    margin: 0 0 12px; }
  .prefix {
    display: block;
    position: relative;
    z-index: 2;
    text-align: center;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid;
    overflow: hidden;
    font-size: 13px;
    height: $input-field-height;
    line-height: $input-field-height; }
  span.prefix {
    background: #f2f2f2;
    border-right: none;
    color: #333;
    border-color: #ccc; }
  input {
    &[type=text], &[type=password], &[type=email] {
      -webkit-appearance: none;
      border-radius: 0;
      background-color: $input-field-background;
      font-family: inherit;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      color: $input-field-text-color;
      display: block;
      font-size: 13px;
      margin: 0;
      padding: 6px;
      height: $input-field-height;
      width: 100%;
      box-sizing: border-box;
      transition: box-shadow 0.45s, border-color 0.45s ease-in-out; }
    &[type=text]:focus, &[type=password]:focus, &[type=email]:focus {
      box-shadow: 0 0 5px #999;
      border-color: #999; }
    &[type=text]:focus, &[type=password]:focus, &[type=email]:focus {
      background: #FFFDF6;
      border-color: #999;
      outline: none; }
    &[type=text]:disabled, &[type=password]:disabled, &[type=email]:disabled {
      background-color: #ddd;
      cursor: default; }
    &[type=radio] {
      margin: 0 0 12px 0; } }
  div, ul, li, form, p {
    margin: 0;
    padding: 0; }
  a {
    color: #0089b8;
    text-decoration: none;
    line-height: inherit;
    &:hover, &:focus {
      color: initial; } }
  p {
    font-family: inherit;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 16px;
    text-rendering: optimizeLegibility; }
  ul {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 16px;
    list-style-position: outside;
    font-family: inherit;
    margin-left: 14px;
    li ul {
      margin-left: 20px;
      margin-bottom: 0; } }
  main {
    a:hover {
      text-decoration: underline; }
    p {
      color: #333;
      font-size: 14px;
      line-height: 23px; } }
  #registerForm .reg-button button.submit {
    color: $button-submit-text;
    font-size: 16px;
    text-shadow: none;
    background: $button-submit;
    border: none;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    &:hover {
      background: $button-submit-hover; } }
  .button, button {
    text-decoration: none;
    position: relative; }
  #registerForm {
    background-color: white;

    border: 1px solid #b7cfdb;
    // top left and top right are wider radius so that they are guaranteed to be drawn
    // BELOW the #reg-header border radius, so that they don't interfere with each other
    border-radius: #{5px+$regbox-border-radius} #{5px+$regbox-border-radius} $regbox-border-radius $regbox-border-radius;
    color: #fff;
    font-size: 12px;
    padding: 0;
    .row {
      margin: 15px 0 0; }
    .columns {
      margin-bottom: 0; }
    #reg-header {
      text-align: center;
      background: $regbox-header;
      padding: 25px 25px 19px;
      border: 1px solid white;

      // make the header go ON TOP OF the containing DIV's border
      border-top-left-radius: $regbox-border-radius;
      border-top-right-radius: $regbox-border-radius;
      margin-top: -1px;
      margin-left: -1px;
      margin-right: -1px;
      border-bottom-width: 0;

      #reg-box-logo {
        background-repeat: no-repeat;
        background-position: center;
        height: 60px;
        svg {
          height: 100%; } }
      p {
        color: #fff;
        margin: 7px 0;
        font-size: 14px; } }

    #reg-body {
      padding: $regbox-body-padding;
      position: relative;
      background-color: $regbox-background-color;
      border-bottom-left-radius: $regbox-border-radius;
      border-bottom-right-radius: $regbox-border-radius;
      #reg-slogan {
        position: relative;
        font-weight: $slogan-font-weight;
        font-size: $slogan-font-size;
        text-align: center;
        color: $slogan-text-color;
        line-height: 1.15;
        margin-bottom: 10px;
        margin-top: -3px; } }

    // the toilet people
    .reg-gender {
      display: flex;
      flex-wrap: wrap;

      .custom-label {
        font-size: 13px;
        color: #333;
        text-align: center;
        display: block; }

      .gender {
        padding: 0 5px;
        flex-shrink: 1; // all shrink at the same pace
        min-width: 110px; // until we're this wide, then...
        flex-grow: 100; // take up all the space if we wrap
        flex-basis: 90px; // and start out at 90px

        ul {
          margin-top: 6px;
          li {
            &.gender-female {
              text-align: right; }
            &.gender-male {
              text-align: left; }
            padding-bottom: 0;
            label {
              .filler {
                .aif {
                  border-radius: 50%;
                  box-shadow: 0 3px 3px rgba(0,0,0,0.19);
                  background: linear-gradient(to bottom, #f4f4f4 0%, #f4f4f4 50%, #ededed 51%, #ededed 100%);
                  color: #fff;
                  padding: 3px;
                  text-align: center;
                  &:before {
                    -webkit-background-clip: text !important /* https://caniuse.com/#feat=css-mediaqueries,background-img-opts */;
                    color: transparent !important; }
                  &.female {
                    &:before {
                      background: linear-gradient(to bottom, #fdc956 0%, #fdc956 50%, #fdc343 51%, #fdc343 100%); } }
                  &.male {
                    &:before {
                      background: linear-gradient(to bottom, #2c6e8c 0%, #2c6e8c 50%, #145d7f 51%, #145d7f 100%); } } } } }
            &:hover .filler,
            input:checked + .filler {
              .aif {
                box-shadow: inset 0 2px 5px 1px rgba(0,0,0,.2);
                &.male,
                &.female {
                  &:before {
                    background: white; } }
                &.female {
                  background: linear-gradient(to bottom, #fdc956 0%, #fdc956 50%, #fdc343 51%, #fdc343 100%); }
                &.male {
                  background: linear-gradient(to bottom, #2c6e8c 0%, #2c6e8c 50%, #145d7f 51%, #145d7f 100%); } } } } } } }

    // input fields for email and password
    .reg-mail,
    .reg-password {
      margin-bottom: 10px;
      border: 1px solid transparent;

      &.input-not-empty {
        &.input-not-valid {
          border: 1px solid $validation-failed-color;
          .columns .prefix {
            color: $validation-failed-color; } }


        &.input-valid {
          border: 1px solid $validation-passed-color;
          .columns .prefix {
            color: $validation-passed-color; } } }

      .columns {
        padding: 0; }

      input,
      .columns .prefix {
        background-color: #ececec;
        border: 0; }

      .columns .prefix {
        color: #b3b3b3;
        position: relative; }

      input {
        color: #55585b;
        padding-right: 2px;
        font-weight: 600;
        font-size: 15px;
        border: none;
        box-shadow: none; } }


    .reg-password {
      .columns .prefix .aif {
        margin-bottom: 4px; }
      input[type=password] {
        border: none;
        box-shadow: none; } }
    .reg-legal {
      margin-top: -10px;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      input#legal {
        flex-shrink: 1;
        flex-grow: 0;
        flex-basis: 10px; }
      label {
        flex: 1; } }
    .reg-legal label {
      display: inline;
      margin-left: 5px; }
    .reg-legal,
    .reg-legal label {
      color: #191b1e;
      font-size: 11px;
      line-height: 1.3;
      a {
        color: inherit;
        text-decoration: underline; }
      &.validation-error {
        display: flex;
        line-height: 1.3;
        font-weight: normal;
        color: $validation-failed-color;
        label, a {
          color: $validation-failed-color; } } }

    .password-requirements {
      position: relative;
      div {
        position: absolute;
        bottom: 4px;
        margin-left: -1px;
        margin-right: -1px;
        padding: 4px 8px;
        color: #67787F;
        font-size: 11px;
        background-color: white;
        border: 1px solid #ccc;
        z-index: 100;
        &:before,
        &:after {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none; }
        &:before {
          border-top-color: #ccc;
          border-width: 13px;
          margin-left: -13px; }
        &:after {
          border-top-color: #fff;
          border-width: 12px;
          margin-left: -12px; }
        .password-requirements-close {
          float: right;
          position: relative;
          margin: -10px -10px 0 0;
          z-index: 20;
          cursor: pointer;
          color: #767676;
          padding: 10px; }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            display: flex;
            > * {
              display: block; }
            .aif {
              position: relative;
              top: 6px;
              padding-right: 6px; }

            &.rule-passed {
              color: $validation-passed-color; }
            &.rule-failed {
              color: $validation-failed-color;
              .aif:before {
                content: "\E02B"; } } } } } } } // exclamation mark


  .aif {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    line-height: 1;
    &:before {
      font-family: "affinitas-icon-font";
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; } }
  .aif_size40 {
    font-size: 40px; }
  .aif_size20 {
    font-size: 26px; } }


//
//
// how the validation errors are styled also depends on where the regbox is
//
//

@mixin validation_error_on_right_hand_side() {
  div {
    // show validation error to the right of the input field
    bottom: initial;
    left: calc(100% + 4px);
    min-width: 200px;
    &:after {
      // convert 'down pointing' arrow to 'left pointing' arrow
      top: calc(50% - 8px);
      left: -16px;
      margin-left: initial;
      margin-top: initial;
      border-top-color: transparent;
      border-right-color: $validation-failed-color; } } }

@mixin validation_error_on_left_hand_side() {
  position: absolute;
  div {
    // show validation error to the left of the input field
    bottom: initial;
    right: 5px;
    min-width: 200px;
    &:after {
      // convert 'down pointing' arrow to 'right pointing' arrow
      top: calc(50% - 8px);
      right: -16px;
      margin-right: initial;
      margin-top: initial;
      border-top-color: transparent;
      border-left-color: $validation-failed-color; } } }

@mixin validation_error_pointing_down() {
  position: relative;
  height: auto;
  z-index: 30;
  color: #fff;
  font-size: 11px;
  line-height: 1.3;
  font-weight: 700;
  div {
    background-color: $validation-failed-color;
    position: absolute;
    padding: 4px;
    bottom: 0;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      border: 8px solid transparent;
      border-top-color: $validation-failed-color;
      margin-left: calc(50% - 12px); // this way it ends up in the middle. Unsure about the math.
      margin-top: 4px; } } }

#regbox_wrapper {
  #registerForm {
    // default styling for validation error: red bar above the input field with arrow pointing down
    .validation-error {
      display: none;
      @include validation_error_pointing_down(); } } }

#regbox_wrapper {
  #registerForm {
    .reg-mail,
    .reg-password {
      // on smaller screens errors in the input fields are displayed as a red bar with arrow pointing down
      @include breakpoint(medium down) {
        div {
          position: relative; } } } } }

.sidebar {
  // in the sidebar validation errors are on the left hand side on smaller screens, on the right hand side on larger ones
  #regbox_wrapper {
    #registerForm {
      .reg-mail,
      .reg-password {
        .validation-error {
          @media screen and (min-width: 1600px) {
            @include validation_error_on_right_hand_side(); }
          @media screen and (min-width: 1024px) and (max-width: 1599px) {
            @include validation_error_on_left_hand_side(); } } } } } }


.single-modern-header {
  // if there's a large header image, we show validation errors on the right
  #regbox_wrapper {
    #registerForm {
      .reg-mail,
      .reg-password {
        .validation-error {
          @media screen and (min-width: 800px) {
            @include validation_error_on_right_hand_side(); } } } } } }




@media only screen {
  #regbox_wrapper {
    .columns {
      position: relative;
      padding-left: 5px;
      padding-right: 5px;
      float: left; }
    .small-3 {
      width: 11%; }
    .small-17 {
      width: 89%; } } }

@media only screen {
  #regbox_wrapper .small-block-grid-2 > li {
    width: 50%;
    list-style: none;
    &:nth-of-type(1n) {
      clear: none; }
    &:nth-of-type(2n+1) {
      clear: both; } } }

@media print {
  #regbox_wrapper {
    * {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important; }
    a {
      text-decoration: underline;
      &:visited {
        text-decoration: underline; }
      &[href]:after {
        content: " (" attr(href) ")"; } }
    p {
      orphans: 3;
      widows: 3; } } }

.topbanner #registerForm {
  max-width: 260px; }

.grecaptcha-badge {
  visibility: hidden !important; }

// make sure there's enough whitespace around the regbox if it's been moved inside the main article text
#mobileRegboxWrapper {
  .register {
    margin: 20px 0; } }

.mainblock {
  .main-text {
    .register {
      margin: 20px 0; } } }

.entry-content {
  #regbox_wrapper {
    margin: 20px 0; } }
