.hentry .entry-meta,
.hentry .entry-meta > * {
  color: #666 !important; }

.single-modern-header {
  @include breakpoint(large up) {
    padding: 120px 0; }
  &.invert {
    @include breakpoint(medium down) {
      min-height: 300px;
      background-color: transparent;
      .post-thumbnail {
        top: 25px;
        left: 25px;
        right: 25px;
        > img {
          opacity: 1;
          width: 100%;
          height: 100%; } } } } }

.single {
  &.single-post-modern {
    @include breakpoint(medium down) {
      .site-content_wrap {
        margin-top: 1em; } } } }

@include breakpoint(medium down) {
  h1, .h1-style {
    font-size: 24px !important; }

  .entry-meta {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; } }

.site-main {
  margin-top: 0 !important; }

// move the site logo into the mobile header, otherwise navigation + logo take up half the screen 🙄
.header-container {
  @include breakpoint(medium down) {
    padding: 0;

    .site-branding {
      position: fixed;
      margin-top: -55px;
      z-index: 1000;
      opacity: 1;
      transition: 0.2s all;
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
      background-color: transparent !important;
      pointer-events: none;
      .site-logo {
        a {
          img {
            max-height: 50px;
            width: auto; } } } } } }

.header-icons + a.header-btn.btn.btn-default {
  background-color: #003472 !important;
  border-color: #003472 !important;
  &:hover {
    background-color: #004ba5 !important;
    border-color: #004ba5 !important; } }

.admin-bar {
  .header-container {
    @include breakpoint(medium down) {
      .site-branding {
        position: absolute; } } } }

.breadcrumbs {
  @include breakpoint(medium down) {
    .row {
      padding-left: 1.5625rem;
      padding-right: 1.5625rem; }
    .breadcrumbs__title {
      padding-left: 0; }
    .breadcrumbs__items {
      padding: 0;
      .team-socials_item {
        // hide share buttons on mobile
        display: none;
        &.team-socials_item_first {
          // 'share your story'
          display: inline-flex;
          line-height: initial;
          font-size: initial;
          margin: 0;
          .btn {
            padding: 0;

            // make it a fat (high) button for fat fingers, without
            // affecting the layout
            padding-top: 16px;
            margin-top: -16px;
            padding-bottom: 16px;
            margin-bottom: -16px; } } } } } }

.search-active {
  @include breakpoint(medium down) {
    .header-container {
      .site-branding {
        opacity: 0; } } } }


.category-success-stories {
  @include breakpoint(medium down) {
    .single-modern-header {
      display: none; } } } // https://jira.affinitas.de/projects/WP/issues/WP-86
